// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Scroll from 'react-scroll';

// Components
import Hero from './Hero';
import LogoList from './LogoList';
import Meta from '../shared/Meta';
import ServicesForm from './ServicesForm';
import Triple from './Triple';
import CtaImage from './CtaImage';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';
import servicesActions from '../../actions/servicesActions';

// Selectors
import { getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';
import { getUrl } from '../../modules/helpers';
import styles from '../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from './Triple.module.postcss';

class Residential extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      submitting: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  onFormSubmit(data) {
    this.setState({ submitting: true });

    this.props.onServicesFormSubmit(data).then(() => {
      this.setState({
        submitted: true,
        submitting: false,
      });
    });

    return false;
  }

  render() {
    const { location, cityConfig } = this.props;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div>
        <Meta
          title={`Food ${cityConfig.vehicle_type}s for Residential Communities | ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`Schedule a daily or weekly rotation of food ${vehicleType}s to visit your apartment residents or HOA community all at little to no cost to you!`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Residential Services",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />
        <Hero photo="https://static.seattlefoodtruck.com/photos/Residential.jpeg">
          <h1>Food {cityConfig.vehicle_type}s @ Home</h1>
          <p>Schedule a daily or weekly rotation of food {vehicleType}s to visit you on site, set up and sell food directly to your apartment residents or HOA community. All at little to no cost to you!</p>
          <Scroll.Link
            smooth
            to="form"
            className="Button"
          >
            Start the conversation
          </Scroll.Link>
        </Hero>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <h4>How it works</h4>
            <div className={tripleStyles.Triple_wrapper}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about1.jpeg"
                title="Curated Schedule"
                text={`Our proprietary platform curates and schedules ${cityConfig.city_name}'s best food ${vehicleType}s to visit your apartment complex or HOA neighborhood on a daily rotation. By learning your residents' favorite food ${vehicleType}s, your schedule changes as it goes, with cuisines from all over the world.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about2.jpg"
                title="Fully Automated"
                text={`Your residents and neighbors receive weekly menu emails of food ${vehicleType}s scheduled each week. A customized web page is also built for your location to view that week's food ${vehicleType} lunch lineup. Put your resident event programs on autopilot.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about3.jpg"
                title="Hassle Free"
                text={`Food ${vehicleType}s arrive, serve and sell lunch or dinner to your residents and leave with no mess behind. Residents can order at the truck or skip the line and order online. All food is made to order from a gourmet kitchen on wheels.`}
              />
            </div>
          </div>
        </section>

        <section className="PaddedSection">
          <div className="Container">
            <LogoList residential/>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <h4>The amenity residents rave about</h4>
            <div className={tripleStyles.Triple_wrapper + " " + tripleStyles.Triple_wrapper__two + " " + tripleStyles.Triple_wrapper__services}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/Attract_More_Residents.jpeg"
                title="Attract More Residents"
                text="Residents consider on-site amenities to be one of the top factors when choosing an apartment community.  Help build a sense of joint activity and community while giving your residents a one-of-a-kind work from home perk."
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/Retain_Happy_Residnets.jpeg"
                title="Retain Happy Residents"
                text={`The year has given many residents delivery fatigue! Food ${vehicleType}s can provide on-site options while maintaining safe distancing protocols, helping residents feel more connected to their neighbors and community.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about6.png"
                title="Drive Down Event Costs"
                text={`Community managers and HOAs can spend over 20 hours per month planning resident events.  Turn your resident perks on auto-pilot, with a rotating selection of delicious cuisines from the best food ${vehicleType}s.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about7.png"
                title="Support Local Businesses"
                text={`Our owner-operated food ${vehicleType}s favor quality, variety, and sustainability. Experience cuisines from all over the world without ever leaving your home.`}
              />
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Resident_Event_Credits.jpeg" imgClass="img-residentialCredits"/>
              <div className={styles.Cta_copy}>
                <h4>Resident Credit Packages, Incentives, and Rewards</h4>

                <p>Offer free meals, credits and a rewards program to incentivize current and prospective residents to sign a new lease or treat those in your neighborhood association!</p>

                <div>
                  <Scroll.Link
                    smooth
                    to="form"
                    className="Button"
                  >
                    Get Started!
                  </Scroll.Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Scroll.Element name="form">
          <section className="PaddedSection--morePadding">
            <div className="Container">
              <h4>Let us help with your community events</h4>
              {this.state.submitting && (
                <p>Sending your inquiry...</p>
              )}
              {this.state.submitted && (
                <p>Your inquiry has been sent. We'll be in touch soon!</p>
              )}
              {!this.state.submitting && !this.state.submitted && (
                <ServicesForm onSubmit={this.onFormSubmit} formType="residential" />
              )}
            </div>
          </section>
        </Scroll.Element>
      </div>
    );
  }
}

Residential.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  onServicesFormSubmit: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    },
    onServicesFormSubmit(data) {
      return dispatch(servicesActions.onServicesFormSubmit(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Residential);
